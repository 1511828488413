<script>
import {mapGetters} from "vuex";
import BillableImplementPartItem from "../elements/billable-implement-part-item.vue";

export default {
    name: "billable-implement-part-form",

    components: {BillableImplementPartItem},

    emits: ['add', 'update', 'close'],

    props: {
        type: {
            type: String
        },

        store: {
            type: String
        },

        ownerProp: {
            type: String
        },

        ownerId: {
            type: [String, Number]
        },

        errors: {
            type: Object,
            default: () => { return {}; }
        },

        states: {
            type: Object,
            default: () => { return {}; }
        }
    },

    computed: {
        ...mapGetters({
            billables: 'billable/all',
            products: 'billable/products',
            services: 'billable/services',
            currencies: 'currency/all'
        }),

        stateProp: function () {
            return this.type === 'edit' ? 'edited' : 'created';
        },

        item: {
            get: function() {
                return this.$store.getters[`implementPart/${this.stateProp}Billable`];
            },
            set: function (model) {
                return this.$store.dispatch(`implementPart/${this.stateProp}BillableUpdate`, model);
            }
        },

        billableModelTypes: function () {
            return [
                {
                    value: 'Appon\\Projects\\Models\\Product',
                    store: 'product',
                    label: this.$tc('projects::products.product', 2)
                },
                {
                    value: 'Appon\\Projects\\Models\\Service',
                    store: 'service',
                    label: this.$tc('projects::services.service', 2)
                }
            ]
        },

        btnDisabled: function () {
            return !this.billable || !this.price
        },
    },

    data: function () {
        return {
            loading: false,
            model: {},
            billable: null,
            price: null,
            prices: [],
            extra: {},
            quantity: 1,
            files: {}
        }
    },

    methods: {
        setFile: function (event, prop = 'file_file') {
            this.model[prop] = this.extra[prop] = this.files[prop] = event.target.files[0]
        },

        setModel: function (model = {}) {
            const base = {
                quantity: this.quantity
            };

            const override = {};

            if (this.ownerProp && this.ownerId) {
                base[this.ownerProp] = this.ownerId;
            }

            if (this.billable) {
                override.billable_id = this.billable.id;
                override.billable_type = this.billable.type;
                override.billable = this.billable;
            }

            if (this.price) {
                override.price = this.price.net
                override.vat = this.price.vat
                override.currency = this.price.currency_code
            }

            this.model = Object.assign(base, this.extra, JSON.parse(JSON.stringify(model)), override);

            Object.keys(this.files).forEach(key => {
                this.model[key] = this.files[key];
            })
        },

        setBillableStore: function () {
            if (!this.model.billable_type) {
                this.billableStore = null
            }

            const type = this.billableModelTypes.find(t => t.value === this.model.billable_type)

            if (type) {
                this.billableStore = type.store
            }
        },

        fetchBillables: function () {
            if (this.billableStore) {
                this.$store.dispatch(`${this.billableStore}/all`);
            } else {
                this.$store.dispatch(`${this.billableStore}/clearAll`);
            }
        },

        setDetails: function () {
            this.setModel(this.item)
            this.prices = this.billable.active_prices
        },

        setPrice: function () {
            this.setModel(this.item)
        },

        save: function () {
            if (this.type === 'edit') {
                this.$emit('update', this.model);
            } else {
                this.$emit('add', this.model);
            }
        },

        close: function () {
            this.$emit('close');
        },
    },

    watch: {
        item: {
            immediate: true,
            deep: true,
            handler: function(value) {
                this.setModel(value);
            },
        },

        extra: {
            immediate: true,
            deep: true,
            handler: function() {
                this.setModel(this.model);
            },
        },

        billableStore: function () {
            this.fetchBillables()
        },

        quantity: function () {
            this.setModel(this.item)
        },
    },

    created() {
        this.$store.dispatch('billable/all', {with: ['activePrices', 'activePrices.currency'] });
        this.$store.dispatch('currency/all');
    },

    unmounted() {
        if (this.billableStore) {
            this.$store.dispatch(`${this.billableStore}/clearAll`);
        }
    }
}
</script>

<template>
    <form>
        <b-overlay :show="loading" rounded>
            <div class="row bg-light mb-4 rounded">
                <div class="col-12 my-3 text-center">
                    <billable-implement-part-item :item="model"></billable-implement-part-item>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-3 mb-4">
                    <label
                        :class="{'text-danger': states.billable_id === false}"
                        class="form-label"
                        for="billable_id-input"
                    >{{ $t('projects::billable_implements.columns.billable_id').ucFirst() }}</label
                    >
                    <select
                        id="billable_id-input"
                        v-model="billable"
                        :class="{'border-danger': states.billable_id === false}"
                        class="form-select"
                        name="billable_id"
                        @change="setDetails"
                    >
                        <option :value="billable === undefined ? undefined : null">
                            {{ $t('projects::billable_implements.placeholders.billable_id').ucFirst() }}
                        </option>
                        <optgroup :label="$tc('projects::products.product', 2).ucFirst()">
                            <option v-for="(item, index) in products" :key="index" :value="item">
                                {{ item.translated }}
                            </option>
                        </optgroup>
                        <optgroup :label="$tc('projects::services.service', 2).ucFirst()">
                            <option v-for="(item, index) in services" :key="index" :value="item">
                                {{ item.translated }}
                            </option>
                        </optgroup>
                    </select>
                    <div :class="{'d-block': states.billable_id === false}" class="invalid-feedback">{{ errors.billable_id }}</div>
                </div>

                <div class="col-lg-3 mb-4" v-if="!price">
                    <label
                        :class="{'text-danger': states.price === false}"
                        class="form-label"
                        for="price-input"
                    >{{ $t('projects::billable_implements.columns.price').ucFirst() }}</label
                    >
                    <select
                        id="price-input"
                        v-model="price"
                        :class="{'border-danger': states.price === false}"
                        class="form-select"
                        name="price"
                        @change="setPrice"
                    >
                        <option :value="billable === undefined ? undefined : null">
                            {{ $t('projects::billable_implements.placeholders.price').ucFirst() }}
                        </option>
                        <option v-for="(item, index) in prices" :key="index" :value="item">
                            {{ item.label }}
                        </option>
                    </select>
                    <div :class="{'d-block': states.price === false}" class="invalid-feedback">{{ errors.price }}</div>
                </div>

                <template v-else>
                    <div class="col-lg-3 mb-4">
                        <label
                            :class="{'text-danger': states.price === false}"
                            class="form-label"
                            for="price-input"
                        >{{ $t('projects::billables.net').ucFirst() }}</label
                        >
                        <input
                            min="0"
                            id="sort"
                            v-model="model.price"
                            :class="{'border-danger': states.price === false}"
                            :placeholder="$t('projects::billables.net').ucFirst()"
                            class="form-control"
                            type="number"
                            step="0.01"
                        />
                        <div :class="{'d-block': states.price === false}" class="invalid-feedback">{{ errors.price }}</div>
                    </div>

                    <div class="col-lg-3 mb-4">
                        <label
                            :class="{'text-danger': states.vat === false}"
                            class="form-label"
                            for="vat-input"
                        >{{ $t('projects::billables.vat').ucFirst() }}</label
                        >
                        <input
                            min="0"
                            id="sort"
                            v-model="model.vat"
                            :class="{'border-danger': states.vat === false}"
                            :placeholder="$t('projects::billables.vat').ucFirst()"
                            class="form-control"
                            type="number"
                            step="0.01"
                        />
                        <div :class="{'d-block': states.vat === false}" class="invalid-feedback">{{ errors.vat }}</div>
                    </div>

                    <div class="col-lg-3 mb-4">
                        <label
                            :class="{'text-danger': states.currency === false}"
                            class="form-label"
                            for="vat-input"
                        >{{ $t('projects::billables.currency').ucFirst() }}</label
                        >
                        <select
                            id="price-input"
                            v-model="model.currency"
                            :class="{'border-danger': states.currency === false}"
                            class="form-select"
                            name="price"
                        >
                            <option :value="model.currency === undefined ? undefined : null">
                                {{ $t('projects::billables.currency').ucFirst() }}
                            </option>
                            <option v-for="(item, index) in currencies" :key="index" :value="item.code">
                                {{ item.translation_key ? $t(item.translation_key) : item.name }}
                            </option>
                        </select>
                        <div :class="{'d-block': states.vat === false}" class="invalid-feedback">{{ errors.vat }}</div>
                    </div>
                </template>

                <div class="col-lg-3 mb-4">
                    <label
                        :class="{'text-danger': states.quantity === false}"
                        class="form-label"
                        for="quantity-input"
                    >{{ $t('projects::billable_implements.columns.quantity').ucFirst() }}</label
                    >
                    <input
                        min="1"
                        id="sort"
                        v-model="model.quantity"
                        :class="{'border-danger': states.quantity === false}"
                        :placeholder="$t('projects::billables.quantity').ucFirst()"
                        class="form-control"
                        type="number"
                    />
                    <div :class="{'d-block': states.quantity === false}" class="invalid-feedback">{{ errors.quantity }}</div>
                </div>

                <slot name="inputs" v-bind:states="states" v-bind:errors="errors" v-bind:extra="extra" v-bind:setFile="setFile"></slot>
            </div>
            <div class="row">
                <div class="col-12 text-center">
                    <b-button variant="secondary" @click="close">{{ $t('base.close').ucFirst() }}</b-button>
                    <b-button class="ms-2" variant="primary" @click="save">{{ $t('base.next').ucFirst() }}</b-button>
                </div>
            </div>
        </b-overlay>
    </form>
</template>

<style scoped>

</style>
