<template>
    <form>
        <b-overlay :show="loading" class="p-1" rounded>
            <div class="row mb-4">
                <div class="col-lg-3 mb-4">
                    <label
                        :class="{'text-danger': states.contracts === false}"
                        class="form-label"
                        for="contracts-input"
                    >{{ $t('projects::implements.relationships.contracts').ucFirst() }}</label
                    >
                    <select
                        id="contracts-input"
                        v-model="model.contracts"
                        :class="{'border-danger': states.contracts === false}"
                        class="form-select"
                        name="contracts"
                        multiple
                        @change="setBillables"
                    >
                        <option v-if="false" :value="model.contracts === undefined ? undefined : null">
                            {{ $t('base.please_select').ucFirst() }}
                        </option>
                        <option v-for="(contract, index) in contracts" :key="index" :value="contract.id">
                            {{ contract.title }}
                        </option>
                    </select>
                    <div :class="{'d-block': states.contracts === false}" class="invalid-feedback">{{ errors.contracts }}</div>
                </div>

                <div class="col-lg-3 mb-4">
                    <label
                        :class="{'text-danger': states.name === false}"
                        class="form-label"
                        for="name"
                    >{{ $t('projects::implements.columns.name').ucFirst() }} *</label
                    >
                    <input
                        id="name"
                        v-model="model.name"
                        :class="{'border-danger': states.name === false}"
                        :placeholder="$t('projects::implements.placeholders.name').ucFirst()"
                        class="form-control"
                        name="name"
                        type="text"
                    />
                    <div :class="{'d-block': states.name === false}" class="invalid-feedback">{{ errors.name }}</div>
                </div>

                <div v-if="false" class="col-lg-3 mb-4">
                    <label
                        :class="{'text-danger': states.realized_at === false}"
                        class="form-label"
                        for="realized_at"
                    >{{ $t('projects::implements.columns.realized_at').ucFirst() }}</label
                    >
                    <input
                        id="realized_at"
                        v-model="model.realized_at"
                        :class="{'border-danger': states.realized_at === false}"
                        :placeholder="$t('projects::implements.placeholders.realized_at').ucFirst()"
                        class="form-control"
                        type="datetime-local"
                    />
                    <div :class="{'d-block': states.realized_at === false}" class="invalid-feedback">
                        {{ errors.realized_at }}
                    </div>
                </div>

            </div>
            <div class="row mb-4">
                <div class="col-12 mb-3">
                    <h3>{{ $tc('invoices::items.item', 2).ucFirst() }}</h3>
                </div>
                <div class="col-12">
                    <billable-implement-part-item class="me-3" v-for="(billable, index) in checkedBillable" :key="index" :item="billable" :validation="true"></billable-implement-part-item>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <implement-part-manager v-model="model.implement_parts" :billables="billables"></implement-part-manager>
                </div>
            </div>
            <div class="row justify-content-end">
                <div class="col-lg-12 text-center">
                    <button class="btn btn-primary" type="submit" @click.prevent="save">
                        {{ btnText }}
                    </button>
                    <slot name="buttons" :save="save"></slot>
                </div>
            </div>
        </b-overlay>
    </form>
</template>

<script>
import {mapGetters} from "vuex";
import ImplementPartManager from "../elements/implement-part-manager.vue";
import BillableImplementPartItem from "../elements/billable-implement-part-item.vue";

export default {
    name: "implement-form",

    components: {BillableImplementPartItem, ImplementPartManager},

    emits: ['saved', 'updated'],

    props: {
        implement: {
            type: Object,
            required: true
        },

        fixed: {
            type: Boolean,
            default: false
        },

        redirect: {
            type: Boolean,
            default: true
        },

        doNotSave: {
            type: Boolean,
            default: false
        }
    },

    computed: {
        ...mapGetters({
            'loading': 'implement/loading',
            'states': 'implement/states',
            'errors': 'implement/errors',
            contracts: 'contract/all',
            project: 'project/item',
            billableImplement: 'billableImplement/item'
        }),

        btnText: function () {
            return this.model.id ? this.$t('base.save').ucFirst() : this.$t('base.create').ucFirst()
        },

        checkedBillable: function () {
            const quantities = {};

            this.model?.implement_parts?.forEach(part => {
                part.billables.forEach(b => {
                    const key = b.billable_type + '_' + b.billable_id;
                    if (! quantities[key]) {
                        quantities[key] = b.quantity;
                    } else {
                        quantities[key] += b.quantity;
                    }
                });
            });

            return this.billables?.map(b => {
                const key = b.billable_type + '_' + b.billable_id;
                if (quantities[key] > -1) {
                    quantities[key] -= b.quantity;
                    console.log(quantities[key], b.quantity)
                }
                console.log(quantities[key])
                b.quantity_ok = quantities[key] >= 0;
                b.valid_item = Object.keys(quantities).includes(key);

                return b;
            });
        },
    },

    data: function () {
        return {
            model: {},
            test: [],
            billables: null
        }
    },

    methods: {
        save: function () {
            const method = this.model.id ? 'update' : 'create'

            if (!this.doNotSave) {
                this.model.query = {with: ['billables', 'project', 'implementParts', 'implementParts.billables', 'implementParts.documenter', 'implementParts.implementers']};

                return this.$store.dispatch(`implement/${method}`, this.model).then(saved => {
                    this.setModel(saved)
                    this.$emit('saved')
                    if (method === 'create') {
                        this.$router.push(this.$t('routes.' + '/interactions/my-implements/:id(\\\\d+)', {id: this.model.id}).rmValidation())
                    }
                })
            } else {
                this.$store.dispatch('implement/setItem', this.model)
                this.$emit('updated', this.model)
            }
        },

        setModel: function (model = {}) {
            const base = {
                contracts: []
            }
            const extra = {}

            if (model.contracts && model.contracts.length && typeof model.contracts[0] === 'object') {
                extra.contracts = model.contracts.map(contract => contract.id)
            }

            /*if (!model.id) {
                base.realized_at = (new Date()).formattedDate();
            }*/

            this.model = Object.assign(base, JSON.parse(JSON.stringify(model)), extra)
        },

        setBillables: function () {
            this.billables = this.model.contracts?.map(c => {
                return this.contracts?.find(con => con.id === c)?.billables;
            }).flat()?.filter(item => !!item);
        },
    },

    watch: {
        implement: function (value) {
            this.setModel(value)
            if (!this.project?.id && value?.project_id) {
                this.$store.dispatch('contract/all', { project_id: value?.project_id, with: ['billables'] });
            }
        },

        project: {
            immediate: true,
            handler: function (value) {
                if (value?.id) {
                    this.$store.dispatch('contract/all', { project_id: value?.id, with: ['billables'] });
                }
            }
        },


    },

    created() {
        this.setModel(this.implement)
        this.$store.dispatch('user/implementers')
    }
}
</script>

<style scoped>

</style>
