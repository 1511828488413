<script>
// import {mapState} from "vuex";
import RelatedUserManager from "../elements/related-user-manager.vue";

export default {
    name: "implement-part-form",

    components: {RelatedUserManager},

    emits: ['update:modelValue'],

    props: {
        /*implement: {
            type: Object,
            required: true
        },

        implementPart: {
            type: Object
        },

        modelValue: {
            type: Object,
            required: true
        },*/

        type: {
            type: String,
            default: 'create'
        },

        states: {
            type: Object,
            default: () => { return{}; }
        },

        errors: {
            type: Object,
            default: () => { return{}; }
        }
    },

    data: function () {
        return {
            updated: 1,
            documented: false,
            completed: false,
        }
    },

    computed: {
        /*...mapState('implementPart', {model: function (state) {
                return this.type === 'edit'
                    ? state.edited
                    : state.created;
        }}),*/

        stateProp: function () {
            return this.type === 'edit' ? 'edited' : 'created';
        },

        model: {
            get: function () {
                return this.$store.getters[`implementPart/${this.stateProp}`];
            },
            set: function (model) {
                this.$store.dispatch(`implementPart/${this.stateProp}Update`, model);
            },
        },

        skeleton: function () {
            return {
                implement_id: this.implement.id,
                implement_part_id: this.implementPart?.id,
                documenter_type: 'App\\Models\\User',
            };
        },
    },

    methods: {
        setDocumenter: function (user) {
            this.model.documenter_type = 'App\\Models\\User';
            this.model.documenter_id = user?.id || null;
            this.model.documenter = user;
            this.updated++;
        },

        setDocumentedAt: function () {
            this.model.documented_at = (new Date).formattedDate();
        },

        setCompletedAt: function () {
            this.model.completed_at = (new Date).formattedDate();
        },
    },

    watch: {
        documented: function (value) {
            if (value) {
                this.setDocumentedAt();
            } else {
                this.model.documented_at = null;
            }
        },

        completed: function (value) {
            if (value) {
                this.setCompletedAt();
            } else {
                this.model.completed_at = null;
            }
        }
    },

    mounted() {
        this.documented = !!this.model.documented_at;
        this.completed = !!this.model.completed_at;
    }
}
</script>

<template>
    <div v-if="model" class="implement-part-form">
        <div class="row mb-4">
            <div class="col-lg-2 mb-4">
                <div class="w-100 h-100 p-3">
                    <div class="py-1 w-100 h-100 font-size-24 text-primary d-flex justify-content-center align-items-center border bg-soft-primary rounded">
                        <i class="fas fa-tag"></i>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 mb-4">
                <label
                    :class="{'text-danger': states.name === false}"
                    class="form-label"
                    for="name"
                >{{ $t('projects::implement_parts.columns.name').ucFirst() }} *</label
                >
                <input
                    id="name"
                    v-model="model.name"
                    :class="{'border-danger': states.name === false}"
                    :placeholder="$t('projects::implement_parts.placeholders.name').ucFirst()"
                    class="form-control"
                    name="name"
                    type="text"
                />
                <div :class="{'d-block': states.name === false}" class="invalid-feedback">{{ errors.name }}</div>
            </div>
        </div>
        <div class="row mb-4">
            <div class="col-lg-2">
                <div class="w-100 h-100 p-3">
                    <div class="py-1 w-100 h-100 font-size-24 text-primary d-flex justify-content-center align-items-center border bg-soft-primary rounded">
                        <i class="fas fa-calendar-alt"></i>
                    </div>
                </div>
            </div>
            <div class="col-lg-10">
                <div class="row">
                    <div class="col-lg-4 mb-4">
                        <label
                            :class="{'text-danger': states.start === false}"
                            class="form-label"
                            for="start"
                        >{{ $t('projects::implement_parts.columns.start').ucFirst() }}</label
                        >
                        <input
                            id="start"
                            v-model="model.start"
                            :class="{'border-danger': states.start === false}"
                            :placeholder="$t('projects::implement_parts.placeholders.start').ucFirst()"
                            class="form-control"
                            name="start"
                            type="datetime-local"
                        />
                        <div :class="{'d-block': states.start === false}" class="invalid-feedback">{{ errors.start }}</div>
                    </div>
                    <div class="col-lg-4 mb-4">
                        <label
                            :class="{'text-danger': states.end === false}"
                            class="form-label"
                            for="end"
                        >{{ $t('projects::implement_parts.columns.end').ucFirst() }}</label
                        >
                        <input
                            id="end"
                            v-model="model.end"
                            :class="{'border-danger': states.end === false}"
                            :placeholder="$t('projects::implement_parts.placeholders.end').ucFirst()"
                            class="form-control"
                            name="end"
                            type="datetime-local"
                        />
                        <div :class="{'d-block': states.end === false}" class="invalid-feedback">{{ errors.end }}</div>
                    </div>
                    <div class="col-lg-4 mb-4">
                        <label
                            :class="{'text-danger': states.deadline === false}"
                            class="form-label"
                            for="deadline"
                        >{{ $t('projects::implement_parts.columns.deadline').ucFirst() }}</label
                        >
                        <input
                            id="deadline"
                            v-model="model.deadline"
                            :class="{'border-danger': states.deadline === false}"
                            :placeholder="$t('projects::implement_parts.placeholders.deadline').ucFirst()"
                            class="form-control"
                            name="deadline"
                            type="datetime-local"
                        />
                        <div :class="{'d-block': states.deadline === false}" class="invalid-feedback">{{ errors.deadline }}</div>
                    </div>
                    <div class="col-lg-3 mb-4">
                        <label
                            :class="{'text-danger': states.is_all_day === false}"
                            class="form-label"
                            for="is_active"
                        >{{ $t('projects::implement_parts.columns.is_all_day').ucFirst() }}</label
                        >
                        <div class="form-check form-switch form-switch-md mb-3 ps-0">
                            <input id="is_active"
                                   v-model="model.is_all_day"
                                   class="form-check-input float-none mx-3"
                                   type="checkbox"
                            >
                        </div>
                        <div :class="{'d-block': states.is_all_day === false}" class="invalid-feedback">
                            {{ errors.is_all_day }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mb-4">
            <div class="col-lg-2">
                <div class="w-100 h-100 p-3">
                    <div class="py-1 w-100 h-100 font-size-24 text-primary d-flex justify-content-center align-items-center border bg-soft-primary rounded">
                        <i class="fas fa-users"></i>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 mb-4">
                <div class="p-2 rounded bg-white">
                    <label
                        :class="{'text-danger': states.users === false}"
                        class="form-label"
                        for="users"
                    >{{ $t('projects::implement_parts.relationships.implementers').ucFirst() }}</label>
                    <related-user-manager v-model="model.implementers" only-implementers></related-user-manager>
                </div>
            </div>

            <div class="col-lg-6 mb-4" :key="updated">
                <div class="p-2 rounded bg-white position-relative">
                    <label
                        :class="{'text-danger': states.documenter === false}"
                        class="form-label"
                        for="users"
                    >{{ $t('projects::implement_parts.relationships.documenter').ucFirst() }}</label>
                    <related-user-manager
                        v-model="model.documenter"
                        store="user"
                        :list="false"
                        :return-user="true"
                        @update:modelValue="setDocumenter"
                    ></related-user-manager>
                </div>
            </div>
        </div>
        <div class="row mb-4">
            <div class="col-lg-2">
                <div class="w-100 h-100 p-3">
                    <div class="py-1 w-100 h-100 font-size-24 text-primary d-flex justify-content-center align-items-center border bg-soft-primary rounded">
                        <i class="fas fa-clipboard-check"></i>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 mb-4">
                <label
                    :class="{'text-danger': states.documented_at === false}"
                    class="form-label"
                    for="is_active"
                >{{ $t('projects::implement_parts.documentation_is_completed').ucFirst() }}</label
                >
                <div class="form-check form-switch form-switch-md mb-3 ps-0">
                    <input id="is_active"
                           v-model="documented"
                           class="form-check-input float-none mx-3"
                           type="checkbox"
                    >
                </div>
                <div :class="{'d-block': states.documented_at === false}" class="invalid-feedback">
                    {{ errors.documented_at }}
                </div>
            </div>

            <div class="col-lg-4 mb-4">
                <label
                    :class="{'text-danger': states.completed_at === false}"
                    class="form-label"
                    for="is_active"
                >{{ $t('projects::implement_parts.columns.completed_at').ucFirst() }}</label
                >
                <div class="form-check form-switch form-switch-md mb-3 ps-0">
                    <input id="is_active"
                           v-model="completed"
                           class="form-check-input float-none mx-3"
                           type="checkbox"
                    >
                </div>
                <div :class="{'d-block': states.completed_at === false}" class="invalid-feedback">
                    {{ errors.completed_at }}
                </div>
            </div>

        </div>
        <div class="row mb-4">
            <div class="col-lg-2 mt-3">
                <div class="w-100 h-100 p-3">
                    <div class="py-1 w-100 h-100 font-size-24 text-primary d-flex justify-content-center align-items-center border bg-soft-primary rounded">
                        <i class="fas fa-map-marker-alt"></i>
                    </div>
                </div>
            </div>
            <div class="col-lg-10 mb-4">
                <label
                    :class="{'text-danger': states.location === false}"
                    class="form-label"
                    for="location"
                >{{ $t('projects::implement_parts.columns.location').ucFirst() }}</label
                >
                <input
                    id="location"
                    v-model="model.location"
                    :class="{'border-danger': states.location === false}"
                    :placeholder="$t('projects::implement_parts.placeholders.location').ucFirst()"
                    class="form-control"
                    name="location"
                    type="text"
                />
                <div :class="{'d-block': states.location === false}" class="invalid-feedback">{{ errors.location }}</div>
            </div>
        </div>
        <div class="row mb-4">
            <div class="col-lg-2 mt-3">
                <div class="w-100 h-100 p-3">
                    <div class="py-1 w-100 h-100 font-size-24 text-primary d-flex justify-content-center align-items-center border bg-soft-primary rounded">
                        <i class="fas fa-sticky-note"></i>
                    </div>
                </div>
            </div>
            <div class="col-lg-10">
                <label
                    :class="{'text-danger': states.notification === false}"
                    class="form-label"
                    for="notification"
                >{{ $t('projects::implement_parts.columns.notification').ucFirst() }}</label
                >

                <textarea
                    id="notification"
                    v-model="model.notification"
                    :class="{'border-danger': states.notification === false}"
                    :placeholder="$t('projects::implement_parts.placeholders.notification').ucFirst()"
                    class="form-control"
                    rows="5"
                />
                <div :class="{'d-block': states.notification === false}" class="invalid-feedback">{{ errors.notification }}</div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.right-0 {
    right: 0;
}
</style>