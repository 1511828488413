<script>
import _ from "lodash"
import Avatar from "./user/avatar.vue";
import TransitionToggle from "../transitions/toggle.vue";

export default {
    name: "user-searcher",

    components: {TransitionToggle, Avatar},

    emits: ['select'],

    props: {
        excludes: {
            type: Array,
            default: () => { return []; }
        },

        limit: {
            type: Number,
            default: 4
        },

        contributorsAllowed: {
            type: Boolean,
            default: false
        },

        onlyImplementers: {
            type: Boolean,
            default: false
        }
    },

    computed: {
        store: function () {
            if (this.onlyImplementers) {
                return 'implementer';
            }

            return this.contributorsAllowed
                ? 'people'
                : 'user';
        },

        loading: function () {
            return this.$store.getters[`${this.store}/loadingSearch`];
        },

        users: function () {
            return this.$store.getters[`${this.store}/searchList`] || [];
        },

        noMore: function () {
            return this.$store.getters[`${this.store}/searchEnd`];
        },
    },

    data: function () {
        return {
            temp: '',
            search: '',
            prev: '',
            selected: [],
            open: false,
            inited: false
        }
    },

    methods: {
        init: function () {
            this.open = true;

            if (!this.users?.length) {
                this.fetch(null)
            }
        },

        fetch: function (search) {
            this.$store.dispatch(`${this.store}/search`, {search: search, per_page: this.limit}).then(() => {
                this.prev = search;
                this.inited = true;
            });
        },

        more: function () {
            this.$store.dispatch(`${this.store}/moreSearch`)
        },

        clear: function () {
            this.selected = [];
            this.$store.dispatch(`${this.store}/clearSearch`);
        },

        select: function (item) {
            this.$emit('select', item);
            this.open = false;
        },

        isSelected: function (id, type) {
            return this.contributorsAllowed
                ? this.excludes.find(exc => exc.id === id && exc.type === type)
                : this.excludes.includes(id);
        },

        empty: function () {
            this.select(null);
            this.clear();
        },
    },

    watch: {
        search: {
            handler: function (value) {
                this.temp = value;
                _.debounce( (value) => {
                    if (this.temp === value) {
                        this.search = value;
                        this.fetch(value);
                    }
                }, 700)(value);
            },
        }
    }
}
</script>

<template>
    <div class="user-search bg-white rounded">
        <div class="row">
            <div class="col-12 mb-4">
                <div class="search-box bg-white">
                    <div class="position-relative">
                        <input v-model="search" @focus="init" type="text" class="form-control bg-light border-0" :placeholder="$t('base.search').ucFirst() + ' ...'">
                        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 24 24" class="eva eva-search-outline search-icon"><g data-name="Layer 2"><g data-name="search"><rect width="24" height="24" opacity="0"></rect><path d="M20.71 19.29l-3.4-3.39A7.92 7.92 0 0 0 19 11a8 8 0 1 0-8 8 7.92 7.92 0 0 0 4.9-1.69l3.39 3.4a1 1 0 0 0 1.42 0 1 1 0 0 0 0-1.42zM5 11a6 6 0 1 1 6 6 6 6 0 0 1-6-6z"></path></g></g></svg>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <div v-if="loading && !inited" class="text-center">
                    <b-spinner></b-spinner>
                </div>
                <template v-else>
                    <transition-toggle>
                        <div v-if="open && users?.length" class="max-h-16 overflow-y-scroll pe-2">
                            <b-overlay :show="loading">
                                <template v-for="(user, index) in users" :key="index">
                                    <div v-if="!isSelected(user.id, user.type)" class="d-flex align-items-center w-100 mb-2 bg-light-hover cursor-pointer" @click="select(user)">
                                        <div class="me-3">
                                            <avatar :user="user" size="md" thumbnail></avatar>
                                        </div>
                                        <div class="flex-grow-1 border-bottom py-3">
                                            <span class="d-block">{{ user.name }}</span>
                                            <span v-if="user?.position" class="badge mb-0" :class="{'bg-soft-primary text-primary': !user.position.color}" :style="{backgroundColor: user.position.color}">
                                            <i v-if="user.position.icon" class="me-1 font-size-9" :class="user.position.icon"></i>
                                            {{ user?.position?.translation_key ? $t(user?.position?.translation_key).ucFirst() : user?.position?.name }}
                                        </span>
                                            <span v-if="user?.specialization" class="badge ms-1 mb-0" :class="{'bg-soft-primary text-primary': !user.specialization.color}" :style="{backgroundColor: user.specialization.color}">
                                            <i v-if="user.specialization.icon" class="me-1 font-size-9" :class="user.specialization.icon"></i>
                                            {{ user?.specialization?.translation_key ? $t(user?.specialization?.translation_key).ucFirst() : user?.specialization?.name }}
                                        </span>
                                            <span v-if="user?.knowledge_level" class="badge ms-1 mb-0" :class="{'bg-soft-primary text-primary': !user.knowledge_level.color}" :style="{backgroundColor: user.knowledge_level.color}">
                                            <i v-if="user.knowledge_level.icon" class="me-1 font-size-9" :class="user.knowledge_level.icon"></i>
                                            {{ user?.knowledge_level?.translation_key ? $t(user?.knowledge_level?.translation_key).ucFirst() : user?.knowledge_level?.name }}
                                        </span>
                                        </div>
                                    </div>
                                </template>
                            </b-overlay>
                        </div>
                        <div v-else>
                            <i class="font-size-10">
                                <i class="fas fa-ban me-1"></i>
                                {{ $t('base.empty_filtered').ucFirst() }}
                            </i>
                        </div>
                    </transition-toggle>
                    <div class="mt-2 text-center bg-light rounded cursor-pointer" @click="empty">
                        <small class="font-size-10">
                            <i class="fas fa-ban me-1"></i>
                            <span class="">{{ $t('base.to_empty').ucFirst() }}</span>
                        </small>
                    </div>
                </template>
                <div class="">
                    <transition-toggle>
                        <b-button variant="outline-light" class="w-100 mt-2" v-if="!noMore && users?.length" @click="more" :disabled="loading">
                            <b-spinner small v-if="loading"></b-spinner>
                            <span v-else>{{ $t('base.more').ucFirst() }}</span>
                        </b-button>
                    </transition-toggle>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
    .max-h-16 {
        max-height: 16rem;
    }

    .user-search {
        /* width */
        ::-webkit-scrollbar {
            width: 4px;
        }

        /* Track */
        ::-webkit-scrollbar-track {
            background: #f1f1f1;
        }

        /* Handle */
        ::-webkit-scrollbar-thumb {
            background: #888;
        }

        /* Handle on hover */
        ::-webkit-scrollbar-thumb:hover {
            background: #555;
        }
    }
</style>