<script>
import BillableImplementPartItem from "./billable-implement-part-item.vue";
import BillableImplementPartForm from "../forms/billable-implement-part-form.vue";
import TransitionToggle from "../transitions/toggle.vue";

export default {
    name: "billable-implement-part-manager",

    components: {TransitionToggle, BillableImplementPartForm, BillableImplementPartItem},

    props: {
        type: {
            type: String,
            default: 'edited'
        }
    },

    computed: {
        items: function () {
            return this.$store.getters[`implementPart/billables`](this.type);
        },

        model: function () {
            if (this.show === 'selector') return null;

            return this.show === 'created' ? this.created : this.selected;
        },
    },

    data: function () {
        return {
            show: 'selector',
            selected: null,
            created: {},
            test: [
                {
                    implementer_part_id: 1,
                    billable: {
                        id: 1,
                        name: 'Test Product',
                        category: {
                            id: 1,
                            name: 'Category',
                            translation_key: null,
                            description: 'Some description ...',
                            description_translation_key: null,
                        },
                        translation_key: null,
                        description: 'Some description ...',
                        description_translation_key: null,
                        leader: 'Some description ...',
                        leader_translation_key: null,
                        is_active: true,
                    },
                    price: null,
                    vat: null,
                    currency: null,
                    quantity: 3
                }
            ],
        };
    },

    methods: {
        select: function (item) {
            this.$store.dispatch('implementPart/editBillable', item);
            this.selected = item;
            this.show = 'edit';
        },

        add: function (item) {
            this.$store.dispatch('implementPart/pushBillable', item);
            this.show = 'selector';
        },

        remove: function () {

        },

        edit: function (item) {
            this.$store.dispatch('implementPart/editBillable', item);
            this.show = 'edit';
        },

        update: function (item) {
            this.$store.dispatch('implementPart/editedBillableUpdate', item);
            this.show = 'selector';
        },

        close: function () {
            this.show = 'selector';
            this.selected = null;
        },

        create: function () {
            this.$store.dispatch('implementPart/createBillable');
            this.show = 'create'
        },
    }


}
</script>

<template>
    <div class="selector">
        <transition-toggle>
            <div v-if="show === 'selector'" class="row">
                <div class="col col-6 col-sm-4 col-lg-2" v-for="(item, index) in items" :key="index">
                    <billable-implement-part-item class="w-100 h-100 d-flex flex-column justify-content-between" :item="item" @click="edit(item)"></billable-implement-part-item>
                </div>
                <div class="col col-6 col-sm-4 col-lg-2" @click="create">
                    <div class="w-100 h-100 d-flex flex-column justify-content-between item-card border rounded d-inline-block bg-gradient bg-soft-success overflow-hidden cursor-pointer">
                        <div class="p-1">
                            <div class="mb-3 text-center font-weight-bold p-2 bg-white rounded border ">
                            <span class="font-size-24 text-success">
                                <i class="fas fa-plus-square"></i>
                            </span>
                            </div>
                            <div class="text-center mb-3 px-2">
                                    <span class="font-size-12">
                                        <i class="fas fa-product-hunt"></i>
                                    </span>
                            </div>
                        </div>
                        <div class="bg-success text-white opacity-75 font-weight-bold font-size-10">
                            <div class="text-center">
                                    <span>
                                        <i class="fas fa-dollar-sign"></i>
                                    </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <billable-implement-part-form :type="show" @close="close" @update="update" @add="add" v-else></billable-implement-part-form>
        </transition-toggle>
    </div>
</template>

<style scoped>

</style>