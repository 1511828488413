<script>
import {mapGetters, mapState} from "vuex";
import ImplementPartLine from "./implement-part-line.vue";
import ImplementPartForm from "../forms/implement-part-form.vue";
import BillableImplementPartManager from "./billable-implement-part-manager.vue";

export default {
    name: "implement-part-manager",

    components: {BillableImplementPartManager, ImplementPartForm, ImplementPartLine},

    emits: ['update:modelValue'],

    props: {
        modelValue: {
            type: Array,
            default: () => { return []; }
        }
    },

    computed: {
        ...mapState({
            parent: state => state.implementPart.parent
        }),

        ...mapGetters({
            created: 'implementPart/created',
            edited: 'implementPart/edited',
            locale: 'trans/locale',
        })
    },

    data: function () {
        return {
            modal: {
                show: false,
                item: {},
                type: ''
            },
            items: [
                {
                    name: 'APPON LINE KFT. 1. nap',
                    start: '2024-05-21 20:00:00',
                    end: '2024-05-21 21:00:00',
                    deadline: '2024-05-21 22:00:00',
                    location: 'Budapest Aréna 11/5.',
                    documenter: {
                        id: 1,
                        name: 'Fehérvári Norbert'
                    },
                    completed_at: null,
                    implementers: [
                        {
                            id: 1,
                            name: 'Kiss Erika'
                        },
                        {
                            id: 1,
                            name: 'Nagy Ferenc'
                        }
                    ],
                    billables: [],
                    implement_parts: [
                        {
                            name: 'APPON LINE KFT. 1. nap 1. oktatás',
                            start: '2024-05-21 20:00:00',
                            end: '2024-05-21 21:00:00',
                            deadline: '2024-05-21 22:00:00',
                            documenter: {
                                id: 1,
                                name: 'Fehérvári Norbert'
                            },
                            completed_at: null,
                            implementers: [],
                            billables: [],
                            implement_parts: [

                            ]
                        }
                    ]
                }
            ]
        };
    },

    methods: {
        add: function () {
            this.$store.dispatch('implementPart/create');

            this.modal = {
                show: true,
                type: 'create'
            };
        },

        edit: function () {
            this.modal = {
                show: true,
                type: 'edit'
            };
        },

        billable: function () {
            this.modal = {
                show: true,
                form: 'billable'
            };
        },

        push: function () {
            if (this.modal.type === 'create') {
                if (! this.parent) {
                    const base = this.modelValue || [];
                    this.$emit('update:modelValue', [...base, this.created]);
                } else {
                    this.$store.dispatch('implementPart/push');
                }
            }

            if (this.modal.type === 'edit') {
                this.$store.dispatch('implementPart/editedUpdate');
            }

            this.$store.dispatch('implementPart/clear');
        }
    }
}
</script>

<template>
    <div class="">
        <h2>{{ $tc('projects::implement_parts.implement_part', 2).ucFirst() }}</h2>
        <ul class="list-unstyled">
            <template v-for="(item, index) in modelValue" :key="index">
                <implement-part-line :item="item" @edit="edit" @add="add" @billable="billable"></implement-part-line>
            </template>
        </ul>
        <b-button class="rounded-circle" size="sm" variant="success" @click="add">
            <i class="fas fa-plus"></i>
        </b-button>
        <b-modal v-model="modal.show" :title="edited?.name" footer-class="justify-content-center" @ok="push" size="lg">
            <template v-if="modal.show">
                <implement-part-form v-if="modal.form !== 'billable'" :type="modal.type"></implement-part-form>
                <billable-implement-part-manager v-else :type="modal.type" ></billable-implement-part-manager>
            </template>
        </b-modal>
    </div>
</template>

<style lang="scss" scoped>
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "@/assets/scss/_variables.scss";
.line {

}

:deep(.line) {
    &:last-of-type {
        &:before {
            mix-blend-mode: darken;
            content: ' ';
            display: block;
            position: absolute;
            top: 40px;
            left: 14px;
            height: calc(100% - 48px);
            width: 3px;
            border-left-width: 3px;
            border-left-style: solid;
            border-image: linear-gradient(to bottom, lighten($primary, 40%) 0,lighten($primary, 30%) 25%,lighten($primary, 40%) 50%,rgba(0,0,0,0) 50%);
            border-image-slice: 1;
        }
    }
}

.x16 {
    width: 36px;
    height: 36px;
    &.start {
        i {
            &:last-of-type {
                top: 10px;
                left: 10px
            }
        }
    }
    &.end {
        i {
            &:last-of-type {
                top: 8px;
                left: 8px
            }
        }
    }
}
</style>